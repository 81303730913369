
import Toolbar from 'primevue/toolbar';
import Dialog from 'primevue/dialog';
import FileUpload from 'primevue/fileupload';
import { default as BgConstructor } from './BgConstructor/BgConstructor.vue';

import { ImgPreview, Loader } from '../../components/ui';

import { computed, defineComponent, ref } from 'vue-demi';
import { Stores, useStores } from '@/store/Stores';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import SceneConstructor from './SceneConstructor/SceneConstructor.vue';

export default defineComponent({
  data() {
    return {
      isConstructorShown: false,
      isSceneConstructorShown: false
    };
  },
  methods: {
    async upload(params) {
      const body = {
        photo: params.files[0],
        base_photo: params.files[0],
        photo_box_photo: params.files[0]
      };

      await this.settingsStore.saveChanges('bg', 'companyBackgrounds', body, this.toast, this.t);
    },

    async deleteBg(params) {
      await this.settingsStore.delete('bg', 'companyBackground', params);
    },

    showConstructor() {
      this.isConstructorShown = true;
    },

    showSceneConstructor() {
      this.isSceneConstructorShown = true;
    }
  },
  setup() {
    const { roomComponentsStore, roomComponentsStoreState, settingsStore, settingsStoreState } = useStores();
    roomComponentsStore.init();

    const { t } = useI18n();
    const toast = useToast();

    const bgs = computed(() => settingsStore.getSeparatedBackgrounds());

    return {
      toast,
      t,
      bgs,

      roomComponentsStore,
      roomComponentsStoreState,

      settingsStore,
      settingsStoreState
    };
  },
  components: { Toolbar, FileUpload, ImgPreview, Loader, Dialog, BgConstructor, SceneConstructor }
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "_heading-2" }
const _hoisted_2 = { class: "grid mt-4 _relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_ImgPreview = _resolveComponent("ImgPreview")!
  const _component_SceneConstructor = _resolveComponent("SceneConstructor")!
  const _component_BgConstructor = _resolveComponent("BgConstructor")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toolbar, null, {
      left: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('scene_constructor.constructed_scenes')), 1)
      ]),
      right: _withCtx(() => [
        _createVNode(_component_Button, {
          onClick: _ctx.showSceneConstructor,
          class: "p-button p-component"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('scene_constructor.add_scene')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roomComponentsStoreState.rooms, (room) => {
        return (_openBlock(), _createBlock(_component_ImgPreview, {
          key: room.id,
          col: "col-3",
          url: room.preview
        }, null, 8, ["url"]))
      }), 128))
    ]),
    _createVNode(_component_SceneConstructor, {
      isShown: _ctx.isSceneConstructorShown,
      "onUpdate:isShown": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isSceneConstructorShown) = $event)),
      bgConstructor: () => _ctx.$refs.bgConstructor
    }, null, 8, ["isShown", "bgConstructor"]),
    _createVNode(_component_BgConstructor, {
      ref: "bgConstructor",
      isShown: _ctx.isConstructorShown,
      "onUpdate:isShown": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isConstructorShown) = $event))
    }, null, 8, ["isShown"])
  ], 64))
}